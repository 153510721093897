export type S25IconType = keyof typeof icons;

export type Icon = {
    title: string;
    name: string;
    hasDarkMode?: boolean;
    dataImage?: string;
    size?: { height: string; width: string };
};

const icons = {
    close: {
        title: "Close",
        name: "close-x",
    },
    event: {
        title: "Event Icon",
        name: "objects--events",
    },
    location: {
        title: "Location Icon",
        name: "objects--locations",
    },
    organization: {
        title: "Organization Icon",
        name: "objects--organizations",
        hasDarkMode: true,
    },
    resource: {
        title: "Resource Icon",
        name: "objects--resources",
    },
    task: {
        title: "Task Icon",
        name: "objects--tasks",
    },
    contact: {
        title: "Contact Icon",
        name: "objects--contacts",
    },
    help: {
        title: "Help",
        name: "help",
    },
    info: {
        title: "Info",
        name: "info",
    },
    chevronUp: {
        title: "Chevron Up",
        name: "chevron-up",
    },
    chevronDown: {
        title: "Chevron Drown",
        name: "chevron-down",
    },
    arrowDown: {
        title: "Arrow Down",
        name: "arrow--down",
    },
    arrowUp: {
        title: "Arrow Up",
        name: "arrow--up",
    },
    star: {
        title: "Star",
        name: "star",
    },
    pen: {
        title: "Edit",
        name: "pen",
        dataImage: `data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQCAYAAAAf8/9hAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAURJREFUeNpidOi/85+BCLC/QJkRRM+bPAGsPim3AMxnBBkAlMSr+ecfBob7Z9YwrNt6kiFa3Qgstvf9S7AhTMTYDtMsISaNIt5WW/qfoAE3Tqxh+He7kSHI2xzMX3rzHNj2F6+egg1kwudsmGaVwAUMSjqKDBYCc8FyIM2wcGDB52yYZhC4sz6B4efVawyeDNcY5r02Zpg89xQ4EFlgthGjGRwLSJpBgIlYm7FpxjCAkOYp8/5heBXFgGOnn4A1/+cwZrh35T5cHKS5Z+YprGEFDoNXXxECbDzG4NBH1lzWdwpFDU4XgKInN9kM7poNtzwYDA2i8KYTFAMmzb4IpkGpDpZQrExlgLb/B2OcXoB44z9DW7UXPJHANH+VC8brAhaYZhDQD85n0EeS/EpEPmEUF3clmJ1fvnwHZ///fwYlHQAEGAByQ7RHbTw62wAAAABJRU5ErkJggg==`,
        size: { height: "16px", width: "16px" },
    },
    plus: {
        title: "Add",
        name: "plus",
    },
    edit: {
        title: "Edit",
        name: "edit",
    },
    cog: {
        title: "Settings",
        name: "settings--cog",
    },
    refresh: {
        title: "Refresh",
        name: "refresh",
    },
    caretUp: {
        title: "Up",
        name: "caret--caret-up",
    },
    caretDown: {
        title: "Down",
        name: "caret--caret-down",
    },
    caretRight: {
        title: "Right",
        name: "caret--caret-right",
    },
    dragHandle: {
        title: "Drag",
        name: "dragHandle",
        dataImage: `data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMjQgMjQiPgogICAgPHRpdGxlPmRyYWctaW5kaWNhdG9yPC90aXRsZT4KICAgIDxjaXJjbGUgY3g9IjgiIGN5PSIxMiIgcj0iMSI+PC9jaXJjbGU+CiAgICA8Y2lyY2xlIGN4PSI4IiBjeT0iNSIgcj0iMSI+PC9jaXJjbGU+CiAgICA8Y2lyY2xlIGN4PSI4IiBjeT0iMTkiIHI9IjEiPjwvY2lyY2xlPgogICAgPGNpcmNsZSBjeD0iMTYiIGN5PSIxMiIgcj0iMSI+PC9jaXJjbGU+CiAgICA8Y2lyY2xlIGN4PSIxNiIgY3k9IjUiIHI9IjEiPjwvY2lyY2xlPgogICAgPGNpcmNsZSBjeD0iMTYiIGN5PSIxOSIgcj0iMSI+PC9jaXJjbGU+Cjwvc3ZnPgo=`,
        size: { height: "1em", width: "1em" },
    },
    check: {
        title: "Check",
        name: "check",
    },
    warningYield: {
        title: "Warning",
        name: "warnings--yield",
    },
    doubleArrowRight: {
        title: "Double Arrow Right",
        name: "arrow--right-double",
    },
    doubleArrowLeft: {
        title: "Double Arrow Left",
        name: "arrow--left-double",
    },
    no: {
        title: "No",
        name: "no",
    },
} as const;

export const S25IconConst = {
    icons: icons as Record<string, Icon>,
};
